var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title1"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation1"
          ),
          records: _vm.insuredPropertyLocationRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title2"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation1"
          ),
          records: _vm.buildingStructureAndUsageRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title3"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation1"
          ),
          records: _vm.buildingAssessmentRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title4"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation1"
          ),
          records: _vm.houseItemAssessmentRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title5"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation1"
          ),
          records: _vm.preciousMetalAssessmentRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepSecondContent.title6"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepSecondContent.explanation2"
          ),
          records: _vm.externalContractsRecords,
        },
      }),
      _c("ReviewContractTable", {
        attrs: {
          title: null,
          "content-page-name": _vm.contentPageName,
          explanation: null,
          records: _vm.externalContractLoansRecords,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }