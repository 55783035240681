import axios from '@/apis/axios';
import store from '@/store';

/**
 * 保険対象の建物情報取得
 */
export const getInsuranceBuilding = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contract/insurance_building`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
