// 保険対象の所在地用
export const insuredPropertyLocationData = [{ key: 'location', value: '', titleLineFlg: false }]

// 建物の構造・用途用
export const buildingStructureAndUsageData = [
  { key: 'usage', value: '', titleLineFlg: false },
  { key: 'usageGrade', value: '', titleLineFlg: false },
  { key: 'buildingDateText', value: '', titleLineFlg: false },
  { key: 'floorNumber', value: '', titleLineFlg: false },
  { key: 'legalStructureName', value: '', titleLineFlg: false },
  { key: 'structureName', value: '', titleLineFlg: false },
  { key: 'roofName', value: '', titleLineFlg: false },
  { key: 'outerWallName', value: '', titleLineFlg: false },
  { key: 'ownershipTypeName', value: '', titleLineFlg: false },
  { key: 'sectionalOwnership', value: '', titleLineFlg: false },
  { key: 'ownerCommonArea', value: '', titleLineFlg: false },
  { key: 'habitableArea', value: '', titleLineFlg: false },
  { key: 'constructionArea', value: '', titleLineFlg: false },
  { key: 'gardenArea', value: '', titleLineFlg: false },
  { key: 'fittingArea', value: '', titleLineFlg: false },
  { key: 'occupiedArea', value: '', titleLineFlg: false },
  { key: 'totalFloorArea', value: '', titleLineFlg: false },
  { key: 'totalRoomNumber', value: '', titleLineFlg: false },
  { key: 'jobName', value: '', titleLineFlg: false },
  { key: 'jobCode', value: '', titleLineFlg: false },
  { key: 'totalWorkPersonNumber', value: '', titleLineFlg: false },
  { key: 'rentalIncomeLandlordFees', value: '', titleLineFlg: true },
  { key: 'monthlyRentPrice', value: '', titleLineFlg: false },
  { key: 'nonRentalRoomName', value: '', titleLineFlg: false },
]

// 建物評価用
export const buildingAssessmentData = [
  { key: 'buildingAssessmentName', value: '', titleLineFlg: false },
  { key: 'buildingAssessmentPrice', value: '', titleLineFlg: false },
  { key: 'buildingAssessmentValuationName', value: '', titleLineFlg: false },
  { key: 'buildingAssessmentDocumentName', value: '', titleLineFlg: false },
]

// 家財評価用
export const houseItemAssessmentData = [
  { key: 'houseItemAssessmentName', value: '', titleLineFlg: false },
  { key: 'houseItemAssessmentPrice', value: '', titleLineFlg: false },
  { key: 'houseItemAssessmentValuationName', value: '', titleLineFlg: false },
  { key: 'houseItemAssessmentFamilyStructure', value: '', titleLineFlg: true },
  { key: 'houseItemAssessmentHolderAge', value: '', titleLineFlg: false },
  { key: 'houseItemAssessmentFamilySize', value: '', titleLineFlg: false },
  { key: 'houseItemAssessmentDocumentName', value: '', titleLineFlg: false },
]

// 貴金属等の評価用
export const preciousMetalAssessmentData = [
  { key: 'preciousMetalAssessmentName', value: '', titleLineFlg: false },
  { key: 'preciousMetalAssessmentMarketPrice', value: '', titleLineFlg: false },
  { key: 'preciousMetalAssessmentValuationName', value: '', titleLineFlg: false },
  { key: 'preciousMetalAssessmentEvidence', value: '', titleLineFlg: false },
  { key: 'preciousMetalAssessmentDetail', value: '', titleLineFlg: false },
]

// 他の保険契約等用
export const externalContractsData = [
  { key: 'externalContractCampanyName', attributeName: 'campanyName', titleLineFlg: false },
  { key: 'externalContractNote', attributeName: 'note', titleLineFlg: false },
  { key: 'externalContractInsuranceTargetName', attributeName: 'insuranceTargetName', titleLineFlg: false },
  { key: 'externalContractEndDate', attributeName: 'endDate', titleLineFlg: false },
  { key: 'externalContractInsurancePrice', attributeName: 'insurancePrice', titleLineFlg: false },
  { key: 'externalContractEarthquakeInsurancePrice', attributeName: 'earthquakeInsurancePrice', titleLineFlg: false },
]
