<template>
  <v-container>
    <!-- 保険の対象の所在地 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title1')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation1')
      "
      :records="insuredPropertyLocationRecords"
    />

    <!-- 建物の構造・用途 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title2')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation1')
      "
      :records="buildingStructureAndUsageRecords"
    />

    <!-- 建物の評価 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title3')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation1')
      "
      :records="buildingAssessmentRecords"
    />

    <!-- 家財の評価 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title4')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation1')
      "
      :records="houseItemAssessmentRecords"
    />

    <!-- 貴金属等の評価 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title5')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation1')
      "
      :records="preciousMetalAssessmentRecords"
    />

    <!-- 他の保険契約等_☆他の保険契約 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepSecondContent.title6')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepSecondContent.explanation2')
      "
      :records="externalContractsRecords"
    />
    
    <!-- 他の保険契約等_住宅金融支援機構等からの融資 -->
    <ReviewContractTable
      :title=null
      :content-page-name="contentPageName"
      :explanation=null
      :records="externalContractLoansRecords"
    />
  </v-container>
</template>
<script>
import ReviewContractTable from '@/pages/contractor/reviewContractTemplates/ReviewContractTable.vue';
import {
  insuredPropertyLocationData,
  buildingStructureAndUsageData,
  buildingAssessmentData,
  houseItemAssessmentData,
  preciousMetalAssessmentData,
  externalContractsData,
} from '@/lib/reviewContractData/stepSecondContentData';
import { getInsuranceBuilding } from '@/apis/contractor/insuranceBuildings';
import { getCompensation } from '@/apis/contractor/compensations';
import { getSolarPower } from '@/apis/contractor/solarPowers';
import { getBuildingAssessment } from '@/apis/contractor/buildingAssessments';
import { getHouseItemAssessment } from '@/apis/contractor/houseItemAssessments';
import { getPreciousMetalAssessment } from '@/apis/contractor/preciousMetalAssessments';
import { getFormItems } from '@/apis/contractor/formItems';
import { getExternalContracts } from '@/apis/contractor/externalContracts';

export default {
  components: {
    ReviewContractTable,
  },
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    contentPageName: 'stepSecondContent',
    insuredPropertyLocationRecords: insuredPropertyLocationData,
    buildingStructureAndUsageRecords: buildingStructureAndUsageData,
    buildingAssessmentRecords: buildingAssessmentData,
    houseItemAssessmentRecords: houseItemAssessmentData,
    preciousMetalAssessmentRecords: preciousMetalAssessmentData,
    externalContractsRecords: [],
    externalContractLoansRecords: [],
  }),
  async mounted() {
    // 保険対象の建物情報を取得
    const insuranceBuilding = await getInsuranceBuilding().catch(() => {});
    // 施設賠償情報を取得
    const compensation = await getCompensation().catch(() => {});
    // 太陽光発電収入情報を取得
    const solarPower = await getSolarPower().catch(() => {});
    // 建物の評価情報を取得
    const buildingAssessment = await getBuildingAssessment().catch(() => {});
    // 家財の評価情報を取得
    const houseItemAssessment = await getHouseItemAssessment().catch(() => {});
    // 貴金属等評価情報を取得
    const preciousMetalAssessment = await getPreciousMetalAssessment().catch(
      () => {}
    );
    // 可変項目情報(保険対象の建物情報)を取得
    const ibFormItems = await getFormItems('insuranceBuilding').catch(() => {});
    // 可変項目情報(施設賠償情報)を取得
    const csFormItems = await getFormItems('compensation').catch(() => {});
    // 可変項目情報(太陽光発電収入)を取得
    const spFormItems = await getFormItems('solarPower').catch(() => {});
    // 他の保険契約情報を取得
    const externalContracts = await getExternalContracts().catch(() => {});

    // 保険の対象の所在地
    this.setInsuredPropertyLocationRecords(insuranceBuilding);

    // 建物の構造・用途
    this.setBuildingStructureAndUsageRecords(
      insuranceBuilding,
      ibFormItems,
      compensation,
      csFormItems,
      solarPower,
      spFormItems
    );

    // 建物の評価
    this.setBuildingAssessmentRecords(buildingAssessment);

    // 家財の評価
    this.setHouseItemAssessmentRecords(houseItemAssessment);

    // 貴金属の評価
    this.setPreciousMetalAssessmentRecords(preciousMetalAssessment);

    // 他の保険契約等
    this.setExternalContractsRecords(externalContracts);
  },

  methods: {
    setInsuredPropertyLocationRecords: function(insuranceBuilding) {
      // 保険の対象の所在地
      this.insuredPropertyLocationRecords.forEach((object, index) => {
        // ★保険の対象の所在地
        if (object.key === 'location') {
          this.$set(
            this.insuredPropertyLocationRecords[index],
            'value',
            insuranceBuilding.fullAddress
          );
        }
      });
    },

    setBuildingStructureAndUsageRecords: function(
      insuranceBuilding,
      ibFormItems,
      compensation,
      csFormItems,
      solarPower,
      spFormItems
    ) {
      // 建物の構造・用途
      this.buildingStructureAndUsageRecords.forEach((object, index) => {
        // ★用法
        this.setStepSecondContentData(
          object.key,
          'usage',
          insuranceBuilding.usage,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★構造級別
        this.setStepSecondContentData(
          object.key,
          'usageGrade',
          insuranceBuilding.usageGrade,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★建築年月
        this.setStepSecondContentData(
          object.key,
          'buildingDateText',
          insuranceBuilding.buildingDateText,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 階数
        this.setStepSecondContentData(
          object.key,
          'floorNumber',
          insuranceBuilding.floorNumber,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 建物の構造
        this.setStepSecondContentData(
          object.key,
          'legalStructureName',
          insuranceBuilding.legalStructureName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 主要構造
        this.setStepSecondContentData(
          object.key,
          'structureName',
          insuranceBuilding.structureName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 屋根
        this.setStepSecondContentData(
          object.key,
          'roofName',
          insuranceBuilding.roofName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 外壁
        this.setStepSecondContentData(
          object.key,
          'outerWallName',
          insuranceBuilding.outerWallName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ⚠︎★建物の所有関係
        this.setStepSecondContentData(
          object.key,
          'ownershipTypeName',
          insuranceBuilding.ownershipTypeName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 区分所有建物
        this.setStepSecondContentData(
          object.key,
          'sectionalOwnership',
          insuranceBuilding.sectionalOwnership,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 区分所有建物共有部分
        this.setStepSecondContentData(
          object.key,
          'ownerCommonArea',
          insuranceBuilding.ownerCommonArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★住居部分の有無
        this.setStepSecondContentData(
          object.key,
          'habitableArea',
          insuranceBuilding.habitableArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 基礎工事
        this.setStepSecondContentData(
          object.key,
          'constructionArea',
          insuranceBuilding.constructionArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 門・塀・垣、物置・車庫等
        this.setStepSecondContentData(
          object.key,
          'gardenArea',
          insuranceBuilding.gardenArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 畳・建具類等
        this.setStepSecondContentData(
          object.key,
          'fittingArea',
          insuranceBuilding.fittingArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★専（占）有面積
        this.setStepSecondContentData(
          object.key,
          'occupiedArea',
          insuranceBuilding.occupiedArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 延床面積
        this.setStepSecondContentData(
          object.key,
          'totalFloorArea',
          insuranceBuilding.totalFloorArea,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★居住用戸室数
        this.setStepSecondContentData(
          object.key,
          'totalRoomNumber',
          insuranceBuilding.totalRoomNumber,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★建物内の職作業
        this.setStepSecondContentData(
          object.key,
          'jobName',
          insuranceBuilding.jobName,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 職作業コード
        this.setStepSecondContentData(
          object.key,
          'jobCode',
          insuranceBuilding.jobCode,
          this.buildingStructureAndUsageRecords,
          index
        );

        // ★作業規模（人数）
        this.setStepSecondContentData(
          object.key,
          'totalWorkPersonNumber',
          insuranceBuilding.totalWorkPersonNumber,
          this.buildingStructureAndUsageRecords,
          index
        );

        // 家賃月額
        this.setStepSecondContentData(
            object.key,
            'monthlyRentPrice',
            insuranceBuilding.monthlyRentPrice,
            this.buildingStructureAndUsageRecords,
            index
        );

        // 非賃貸戸室名
        this.setStepSecondContentData(
            object.key,
            'nonRentalRoomName',
            insuranceBuilding.nonRentalRoomName,
            this.buildingStructureAndUsageRecords,
            index
        );
      });

      // 中項目: insuranceBuildings.name、小項目: formItems.title
      if (insuranceBuilding.name !== null && ibFormItems.length >= 1) {
        // 家賃収入
        this.buildingStructureAndUsageRecords.push({
          key: insuranceBuilding.name,
          value: '',
          titleLineFlg: true
        });

        // 約定復旧期間（家賃収入）
        ibFormItems.forEach(ibFormItem => {
          const attribute = ibFormItem.insuranceBuildings[0].formItemModels.columnName
          const attributeValue = ibFormItem.insuranceBuildings[0][attribute]

          this.buildingStructureAndUsageRecords.push({
            key: ibFormItem.title,
            value: attributeValue,
          });
        })
      }

      // 中項目: compensation.name、小項目: formItems.title
      if (compensation.name !== null && csFormItems.length >= 1) {
        // 施設賠償責任（タイトル）
        this.buildingStructureAndUsageRecords.push({
          key: compensation.name,
          value: '',
          titleLineFlg: true
        });

        // 業種, 業務遂行名称
        csFormItems.forEach(csFormItem => {
          const attribute = csFormItem.compensations[0].formItemModels.columnName
          const attributeValue = csFormItem.compensations[0][attribute]

          this.buildingStructureAndUsageRecords.push({
            key: csFormItem.title,
            value: attributeValue,
          });
        })
      }

      // 中項目: solarPower.name、小項目: formItems.title
      if (solarPower.name !== null && spFormItems.length >= 1) {
        // 太陽光発電利益（タイトル）
        this.buildingStructureAndUsageRecords.push({
          key: solarPower.name,
          value: '',
          titleLineFlg: true
        });

        // 発電利益月額、約定復旧期間（太陽光発電利益）
        spFormItems.forEach(spFormItem => {
          const attribute = spFormItem.solarPowers[0].formItemModels.columnName
          const attributeValue = spFormItem.solarPowers[0][attribute]
          /**
           * 当該項目はkeyが可変となるが約定復旧期間（家賃収入）と
           * buildingStructureAndUsageRecordsのKeyが被ってしまう可能性があるため
           * keyに半角空白を付加する。（コンソールエラーを回避するため）
           * buildingStructureAndUsageRecords参照時の制約は特になし
           */
          this.buildingStructureAndUsageRecords.push({
            key: `${spFormItem.title} `,
            value: attributeValue,
          });
        })
      }
    },

    setBuildingAssessmentRecords: function(buildingAssessment) {
      // 建物の評価
      this.buildingAssessmentRecords.forEach((object, index) => {
        // 評価・支払基準
        this.setStepSecondContentData(
          object.key,
          'buildingAssessmentName',
          buildingAssessment.name,
          this.buildingAssessmentRecords,
          index
        );

        // 新価評価額
        this.setStepSecondContentData(
          object.key,
          'buildingAssessmentPrice',
          buildingAssessment.price,
          this.buildingAssessmentRecords,
          index
        );

        // 評価方法
        this.setStepSecondContentData(
          object.key,
          'buildingAssessmentValuationName',
          buildingAssessment.valuationName,
          this.buildingAssessmentRecords,
          index
        );

        // 添付書類
        this.setStepSecondContentData(
          object.key,
          'buildingAssessmentDocumentName',
          buildingAssessment.documentName,
          this.buildingAssessmentRecords,
          index
        );
      });
    },

    setHouseItemAssessmentRecords: function(houseItemAssessment) {
      // 家財の評価
      this.houseItemAssessmentRecords.forEach((object, index) => {
        // 評価・支払基準
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentName',
          houseItemAssessment.name,
          this.houseItemAssessmentRecords,
          index
        );

        // 家財評価額
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentPrice',
          houseItemAssessment.price,
          this.houseItemAssessmentRecords,
          index
        );

        // 評価方法
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentValuationName',
          houseItemAssessment.valuationName,
          this.houseItemAssessmentRecords,
          index
        );

        // 世帯主年齢
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentHolderAge',
          houseItemAssessment.holderAge,
          this.houseItemAssessmentRecords,
          index
        );

        // 大人／子供
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentFamilySize',
          this.familySizeString(houseItemAssessment),
          this.houseItemAssessmentRecords,
          index
        );

        // 添付書類
        this.setStepSecondContentData(
          object.key,
          'houseItemAssessmentDocumentName',
          houseItemAssessment.documentName,
          this.houseItemAssessmentRecords,
          index
        );
      });
    },

    setPreciousMetalAssessmentRecords: function(preciousMetalAssessment) {
      // 貴金属の評価
      this.preciousMetalAssessmentRecords.forEach((object, index) => {
        // 評価・支払基準
        this.setStepSecondContentData(
          object.key,
          'preciousMetalAssessmentName',
          preciousMetalAssessment.name,
          this.preciousMetalAssessmentRecords,
          index
        );

        // 時価評価額
        this.setStepSecondContentData(
          object.key,
          'preciousMetalAssessmentMarketPrice',
          preciousMetalAssessment.marketPrice,
          this.preciousMetalAssessmentRecords,
          index
        );

        // 評価方法
        this.setStepSecondContentData(
          object.key,
          'preciousMetalAssessmentValuationName',
          preciousMetalAssessment.valuationName,
          this.preciousMetalAssessmentRecords,
          index
        );

        // その他算出根拠
        this.setStepSecondContentData(
          object.key,
          'preciousMetalAssessmentEvidence',
          preciousMetalAssessment.evidence,
          this.preciousMetalAssessmentRecords,
          index
        );

        // 貴金属等の詳細
        this.setStepSecondContentData(
          object.key,
          'preciousMetalAssessmentDetail',
          preciousMetalAssessment.detail,
          this.preciousMetalAssessmentRecords,
          index
        );
      });
    },

    setExternalContractsRecords: function(externalContracts) {
      // これより画面下部の項目は画面レイアウトが特殊な為、externalContractsDataには定義しない。
      // 他の保険契約等
      for (let i = 0; i < externalContracts.length; i++) {
        // 他の保険契約１(order === 1)は全項目表示(全ての項目がNULLでも表示)
        // 他の保険契約2〜(order !== 1)は全て項目がNULLの場合は非表示
        // 他の保険契約2〜(order !== 1)は値がある項目が存在したら全項目表示(NULLの項目も表示)
        if (
            externalContracts[i].order === 1 ||
            (
              externalContracts[i].campanyName !== null ||
              externalContracts[i].note !== null ||
              externalContracts[i].insuranceTargetName !== null ||
              externalContracts[i].endDate !== null ||
              externalContracts[i].insurancePrice !== null ||
              externalContracts[i].earthquakeInsurancePrice !== null
            )
        ) {
          // 「満期日」の「yyyy-mm-dd」を「yyyy/mm/dd」に置換
          externalContracts[i].endDate = externalContracts[i].endDate ? externalContracts[i].endDate.replaceAll('-', '/') : null;
          
          // ☆他の保険契約
          this.externalContractsRecords.push({
            key:
                this.$t(
                    `header.reviewContractTable.stepSecondContent.externalContract`
                ) +
                (i + 1),
            value: '',
            titleLineFlg: true
          });

          externalContractsData.forEach(externalContractData => {
            this.externalContractsRecords.push({
              key: `${externalContractData.key}${i + 1}`,
              value: externalContracts[i][externalContractData.attributeName],
              titleLineFlg: externalContractData.titleLineFlg
            });
          });
        }
      }
      // 住宅金融支援機構等からの融資
      this.externalContractLoansRecords.push({
        key: this.$t(
          `header.reviewContractTable.stepSecondContent.externalContractExternalContractLoan`
        ),
        value: this.contract.externalContractLoan,
        titleLineFlg: false
      });
    },

    setStepSecondContentData: function(
      key,
      keyName,
      data,
      dataObject,
      index
    ) {
      if (key === keyName) {
          this.$set(dataObject[index], 'value', data);
      }
    },

    familySizeString: function(houseItemAssessment) {
      if(houseItemAssessment.adultFamilySize && houseItemAssessment.childFamilySize) {
          return houseItemAssessment.adultFamilySize + '／' + houseItemAssessment.childFamilySize
        }
        if(houseItemAssessment.adultFamilySize && !houseItemAssessment.childFamilySize) {
          return houseItemAssessment.adultFamilySize
        }
        if(!houseItemAssessment.adultFamilySize && houseItemAssessment.childFamilySize) {
          return houseItemAssessment.childFamilySize
        }
        return null;
    },
  },
};
</script>
<style>
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
